import {
    Box,
    Checkbox,
    IconButton,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
} from '@mui/material';
import { CompanyPerformanceAssessment } from 'api/hooks/company-performance-assessment/companyPerformanceAssessmentTypes';

import { FC } from 'react';
import { AnswerValidationCombination } from './answer-validation-combination';
import ChatIcon from '@mui/icons-material/Chat';
import { QaStatusComponent } from '../qa-status';
import { QaStatus } from 'api/hooks/commonTypes';
import HelpIcon from '@mui/icons-material/Help';

type Props = {
    data: {
        data: CompanyPerformanceAssessment;
        isSelected: boolean;
    }[];
    onChange: (data: CompanyPerformanceAssessment) => void;
    handleOpenChat: (id: string) => void;
    isReadOnly: boolean;
    handleStatusChange: (
        qaStatus: QaStatus,
        row: CompanyPerformanceAssessment
    ) => void;
    onCheck: (id: string) => void;
};

export const CustomTableBody: FC<Props> = ({
    data,
    onChange,
    handleOpenChat,
    isReadOnly,
    handleStatusChange,
    onCheck,
}) => {
    return (
        <>
            <TableBody>
                {data.map(({ data: row, isSelected }) => {
                    const handleChange = (
                        name: string,
                        formulaName: string,
                        value: string,
                        formulaValue: string
                    ) => {
                        // const { name, value } = event.target;
                        const updatedRow = {
                            ...row,
                            [name]: value === '' ? null : value,
                            [formulaName]:
                                formulaValue === '' ? null : formulaValue,
                        };
                        onChange(updatedRow);
                    };

                    return (
                        <TableRow
                            key={row._id}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component='th' scope='row'>
                                <Checkbox
                                    disabled={!row.for_qa}
                                    checked={isSelected}
                                    value={isSelected}
                                    onChange={() => onCheck(row._id)}
                                />
                            </TableCell>
                            <TableCell component='th' scope='row'>
                                {row.section}
                            </TableCell>
                            <TableCell align='right'>{row.topic}</TableCell>
                            {/* <TableCell align='right'>
                                {row.link_to_at}
                            </TableCell> */}
                            <TableCell align='right'>{row.unique_id}</TableCell>
                            <TableCell align='right'>
                              <Box sx={{display: 'flex', alignItems: 'center'}}>
                                {row.question}
                                {row?.guidance && (
                                    <Tooltip title={row?.guidance}>
                                        <IconButton>
                                            <HelpIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell align='right'>
                                <Box
                                    display={'flex'}
                                    justifyContent={'flex-end'}
                                >
                                    <AnswerValidationCombination
                                        answerValidation={
                                            row.year_before_previous_fiscal_year_answer_validation
                                        }
                                        name='answer_for_year_before_previous_fiscal_year'
                                        formulaName='formula_for_year_before_previous_fiscal_year'
                                        value={
                                            row.answer_for_year_before_previous_fiscal_year ??
                                            ''
                                        }
                                        formula={
                                            row.formula_for_year_before_previous_fiscal_year ??
                                            ''
                                        }
                                        onChange={handleChange}
                                        isReadOnly={isReadOnly}
                                    />
                                </Box>
                            </TableCell>
                            <TableCell align='right'>
                                <Box
                                    display={'flex'}
                                    justifyContent={'flex-end'}
                                >
                                    <AnswerValidationCombination
                                        answerValidation={
                                            row.previous_fiscal_year_answer_validation
                                        }
                                        name='answer_for_previous_fiscal_year'
                                        formulaName='formula_for_previous_fiscal_year'
                                        value={
                                            row.answer_for_previous_fiscal_year ??
                                            ''
                                        }
                                        formula={
                                            row.formula_for_previous_fiscal_year ??
                                            ''
                                        }
                                        onChange={handleChange}
                                        isReadOnly={isReadOnly}
                                    />
                                </Box>
                            </TableCell>
                            <TableCell align='right'>
                                <Box
                                    display={'flex'}
                                    justifyContent={'flex-end'}
                                >
                                    <AnswerValidationCombination
                                        answerValidation={row.answer_validation}
                                        name='answer_for_current_fiscal_year'
                                        formulaName='formula_for_current_fiscal_year'
                                        value={
                                            row.answer_for_current_fiscal_year ??
                                            ''
                                        }
                                        formula={
                                            row.formula_for_current_fiscal_year ??
                                            ''
                                        }
                                        onChange={handleChange}
                                        isReadOnly={isReadOnly}
                                    />
                                </Box>
                            </TableCell>
                            <TableCell>{row?.qa_status}</TableCell>
                            <TableCell>
                                <IconButton
                                    onClick={() => handleOpenChat(row._id)}
                                >
                                    <ChatIcon
                                        fontSize='large'
                                        color={
                                            row.has_comments
                                                ? 'success'
                                                : 'primary'
                                        }
                                    />
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                {!isReadOnly && (
                                    <QaStatusComponent
                                        for_qa={row.for_qa}
                                        qa_status={row.qa_status}
                                        handleClick={(qaStatus) =>
                                            handleStatusChange(qaStatus, row)
                                        }
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </>
    );
};
