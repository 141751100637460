import { Container } from '@mui/system';
import { ResetPasswordContainer } from './styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RoutePaths } from 'routes';
import { useResetPassword } from 'api/hooks/';
import { FC } from 'react';
import { ResetPasswordForm } from 'components/reset-password-form';
import { Header } from 'components/header';
import backgroundImage from 'assets/login-background.svg';
import { Box, Paper } from '@mui/material';
import { WithSpinner } from 'components/with-spinner';
import { ResetPasswordRequest } from 'api/hooks/user/userTypes';
import { toast } from 'react-toastify';

export const ResetPassword: FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { mutateAsync: resetPassword, isLoading } = useResetPassword({
        onError: (error: any) => {
            toast.error(error.response.data.detail);
        },
    });

    const handleSubmit = async (password: string) => {
        const token = searchParams.get('token');
        if (token && password) {
            const request: ResetPasswordRequest = {
                new_password: password,
                token: token,
            };
            await resetPassword(request);
            navigate(RoutePaths.DASHBOARD);
        }
    };

    return (
        <WithSpinner isLoading={isLoading}>
            <Box>
                <Header onlyView={true} />
                <ResetPasswordContainer
                    style={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                    }}
                >
                    <Container maxWidth='sm' component={Paper} sx={{ p: 4 }}>
                        <ResetPasswordForm onSubmit={handleSubmit} />
                    </Container>
                </ResetPasswordContainer>
            </Box>
        </WithSpinner>
    );
};
