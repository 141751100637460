import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    SelectChangeEvent,
    Stack,
    TextField,
} from '@mui/material';
import { FC, useEffect } from 'react';
import { CompanyInformationEmployeeNumber } from 'api/hooks/assessment/assessmentTypes';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dateToAPIDate } from 'utils/common';
import {
    CustomSelectField,
    generateSelectFieldProps,
} from 'components/shared/form/custom-select-field';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useGetCountryList } from 'api/hooks/country/useGetCountry';
import { useUpdateCompanyInformationBasic } from 'api/hooks/assessment/useUpdateCompanyInformationBasic';
import {
    EnumToDropdownOptions,
    multipleSelectOptionsToString,
    stringToMultipleSelectOptions,
} from 'utils';
import { useGetCompanyInformation } from 'api/hooks/assessment/useGetCompanyInformation';
import {
    CustomTextField,
    generateTextFieldProps,
} from 'components/shared/form/custom-text-field';
import {
    CustomMultipleSelectField,
    generateMultipleSelectFieldProps,
} from 'components/shared/form/custom-multiple-select-field';

type CompanyInformationEditDialogComponentProps = {
    assessmentId: string;
    open: boolean;
    setOpen: (isOpen: boolean) => void;
};

const CompanyInformationEditDialogComponent: FC<
    CompanyInformationEditDialogComponentProps
> = ({ open, setOpen, assessmentId }) => {
    const formName = 'CompanyInformationEditForm';
    const validationSchema = Yup.object({
        ar_publication_date: Yup.string().required('Required'),
        website: Yup.string().required('Required'),
        fiscal_year_end: Yup.string().required('Required'),
        employee_number: Yup.string().required('Required'),
        sub_industry_rationale: Yup.string().required('Required'),
        country_listed: Yup.string().required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            ar_publication_date: '',
            website: '',
            fiscal_year_end: '',
            employee_number: '',
            sub_industry_rationale: '',
            country_listed: '',
        },
        validationSchema,
        onSubmit: async (data) => {
            await update({
                id: assessmentId,
                request: {
                    ar_publication_date: dateToAPIDate(
                        new Date(data.ar_publication_date ?? '')
                    ),
                    employee_number: data.employee_number,
                    fiscal_year_end: dateToAPIDate(
                        new Date(data.fiscal_year_end ?? '')
                    ),
                    sub_industry_rationale: data.sub_industry_rationale ?? '',
                    country_listed: data.country_listed.split(';'),
                    website: data.website,
                },
            });

            setOpen(false);
        },
    });
    type FormikTypes = typeof formik.initialValues;

    const handleSelect = (event: unknown | SelectChangeEvent) => {
        const { name, value } = (event as SelectChangeEvent).target;
        formik.setFieldValue(name, value);
    };

    const handleMultipleSelect = (event: unknown | SelectChangeEvent) => {
        const { name, value } = (event as SelectChangeEvent).target;
        const newValue = Array.isArray(value)
            ? value.length
                ? multipleSelectOptionsToString(value)
                : null
            : value;

        formik.setFieldValue(name, newValue);
    };

    const handleCountryDeleteClick = (value: string) => {
        const newArray = stringToMultipleSelectOptions(
            formik.values.country_listed
        )?.filter((country) => {
            return country !== value;
        });
        const newValues = multipleSelectOptionsToString(newArray);

        formik.setFieldValue(
            'country_listed',
            newValues === '' ? null : newValues
        );
    };

    const { data: companyInformation, isLoading: companyInformationLoading } =
        useGetCompanyInformation({
            assessmentId: assessmentId ?? '',
            enabled: !!assessmentId && assessmentId !== '0',
        });

    useEffect(() => {
        formik.setValues({
            ar_publication_date:
                companyInformation?.ar_publication_date?.toString() ?? '',
            website: companyInformation?.website ?? '',
            fiscal_year_end:
                companyInformation?.fiscal_year_end?.toString() ?? '',

            employee_number:
                companyInformation?.employee_number ??
                '',
            sub_industry_rationale:
                companyInformation?.sub_industry_rationale ?? '',
            country_listed: multipleSelectOptionsToString(
                companyInformation?.country_listed
            ),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        companyInformation?.ar_publication_date,
        companyInformation?.country_listed,
        companyInformation?.employee_number,
        companyInformation?.fiscal_year_end,
        companyInformation?.sub_industry_rationale,
        companyInformation?.website,
    ]);

    const { mutateAsync: update } = useUpdateCompanyInformationBasic({});

    const { data: countries, isLoading: countriesLoading } = useGetCountryList(
        {}
    );

    const countriesOptions = countries?.map((country) => ({
        value: country.code,
        label: country.name,
    }));

    const employeeNumberTypeOptions = EnumToDropdownOptions(
        CompanyInformationEmployeeNumber
    );

    const isLoading = countriesLoading || companyInformationLoading;

    if (isLoading) {
        return null;
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
            maxWidth='sm'
        >
            <DialogTitle>Analyst/Assurer Edit</DialogTitle>
            <DialogContent>
                <Stack spacing={4} p={2}>
                    <Stack direction={'row'} spacing={2}>
                        <DesktopDatePicker
                            label='Fiscal Year End'
                            inputFormat='DD/MM/YYYY'
                            value={formik.values.fiscal_year_end ?? null}
                            onChange={(newValue) => {
                                formik.setFieldValue(
                                    'fiscal_year_end',
                                    newValue
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    variant='outlined'
                                />
                            )}
                        />
                        <DesktopDatePicker
                            label='AR release date'
                            inputFormat='DD/MM/YYYY'
                            value={formik.values.ar_publication_date ?? null}
                            onChange={(newValue) => {
                                formik.setFieldValue(
                                    'ar_publication_date',
                                    newValue
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    variant='outlined'
                                />
                            )}
                        />
                    </Stack>
                    <Stack direction={'row'} spacing={2}>
                        <CustomTextField
                            {...generateTextFieldProps<FormikTypes>(
                                'website',
                                'Website',
                                formName,
                                formik
                            )}
                        />
                        <CustomSelectField
                            options={employeeNumberTypeOptions}
                            onChange={handleSelect}
                            {...generateSelectFieldProps<FormikTypes>(
                                'employee_number',
                                'Number of Employees',
                                formName,
                                formik
                            )}
                        />
                    </Stack>

                    <Stack direction={'row'} spacing={2}>
                        <CustomTextField
                            {...generateTextFieldProps<FormikTypes>(
                                'sub_industry_rationale',
                                'Sub - Industry Rationale',
                                formName,
                                formik
                            )}
                        />
                        <CustomMultipleSelectField
                            options={countriesOptions}
                            onChange={handleMultipleSelect}
                            {...generateMultipleSelectFieldProps<FormikTypes>(
                                'country_listed',
                                'Country Registered',
                                formName,
                                formik
                            )}
                            handleDelete={handleCountryDeleteClick}
                        />
                    </Stack>

                    <Stack
                        direction={'row'}
                        spacing={2}
                        justifyContent={'flex-end'}
                    >
                        <Button
                            variant='outlined'
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </Button>

                        <Button
                            variant='contained'
                            onClick={() => formik.submitForm()}
                        >
                            Save
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

type CompanyInformationEditProps = {
    open: boolean;
    setOpen: (isOpen: boolean) => void;
};

export const CompanyInformationEdit: FC<CompanyInformationEditProps> = ({
    open,
    setOpen,
}) => {
    const { assessmentId } = useParams();

    return (
        <>
            <CompanyInformationEditDialogComponent
                open={open}
                setOpen={setOpen}
                assessmentId={assessmentId ?? ''}
            />
        </>
    );
};
