import { Button, Stack, TextField } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import {
    CustomTextField,
    generateTextFieldProps,
} from 'components/shared/form/custom-text-field';
import {
    CustomSelectField,
    generateSelectFieldProps,
} from 'components/shared/form/custom-select-field';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import {
    CustomMultipleSelectField,
    generateMultipleSelectFieldProps,
} from 'components/shared/form/custom-multiple-select-field';
import {
    EnumToDropdownOptions,
    multipleSelectOptionsToString,
    stringToMultipleSelectOptions,
} from 'utils';
import {
    CompanyInformation,
    CompanyInformationEmployeeNumber,
    CreateUpdateCompanyInformation,
} from 'api/hooks/assessment/assessmentTypes';
import { Company } from 'api/hooks/company/companyTypes';
import { Industry } from 'api/hooks/industry/industryTypes';
import { Country } from 'api/hooks/country/countryTypes';
import * as Yup from 'yup';
import { dateToAPIDate } from 'utils/common';

type Props = {
    companyInformation?: CompanyInformation;
    companiesData: Company[];
    industriesData: Industry[];
    countries: Country[];
    onCancel?: () => void;
    onGoBack?: () => void;
    onSave?: (
        request: CreateUpdateCompanyInformation,
        needToContinueFlow: boolean
    ) => void;
    isReadOnly?: boolean;
};

export const AssessmentCompanyInformationForm: FC<Props> = ({
    companyInformation,
    companiesData,
    industriesData,
    countries,
    onCancel,
    onSave,
    onGoBack,
    isReadOnly = false,
}) => {
    const [isSave, setIsSave] = useState(false);

    const formName = 'CompanyInformationForm';
    const validationSchema = Yup.object({
        ar_publication_date: Yup.string().required('Required'),
        website: Yup.string().required('Required'),
        fiscal_year_end: Yup.string().required('Required'),
        industry: Yup.string().required('Required'),
        sub_industries: Yup.string().required('Required'),
        employee_number: Yup.string().required('Required'),
        sub_industry_rationale: Yup.string().required('Required'),
        country_listed: Yup.string().required('Required'),
    });
    const formik = useFormik({
        initialValues: {
            isin: companyInformation?.isin ?? '',
            ar_publication_date:
                companyInformation?.ar_publication_date?.toString() ?? '',
            website: companyInformation?.website ?? '',
            fiscal_year_end:
                companyInformation?.fiscal_year_end?.toString() ?? '',
            industry: companyInformation?.industry ?? '',
            sub_industries: multipleSelectOptionsToString(
                companyInformation?.sub_industries?.map(
                    (subIndustry) => subIndustry ?? ''
                ) ?? []
            ),
            employee_number:
                companyInformation?.employee_number ??
                '',
            sub_industry_rationale:
                companyInformation?.sub_industry_rationale ?? '',
            country_listed: multipleSelectOptionsToString(
                companyInformation?.country_listed
            ),
        },
        validationSchema,
        onSubmit: (data) => {
            onSave &&
                onSave(
                    {
                        ar_publication_date: dateToAPIDate(
                            new Date(data.ar_publication_date ?? '')
                        ),
                        employee_number: data.employee_number,
                        fiscal_year_end: dateToAPIDate(
                            new Date(data.fiscal_year_end ?? '')
                        ),
                        industry: data.industry,
                        sub_industries: data.sub_industries.split(';'),
                        sub_industry_rationale:
                            data.sub_industry_rationale ?? '',
                        country_listed: data.country_listed.split(';'),
                        website: data.website,
                    },
                    !isSave
                );
        },
    });
    type FormikTypes = typeof formik.initialValues;

    const handleSelect = (event: unknown | SelectChangeEvent) => {
        const { name, value } = (event as SelectChangeEvent).target;
        formik.setFieldValue(name, value);
    };

    const handleSelectIndustry = (event: unknown | SelectChangeEvent) => {
        const { name, value } = (event as SelectChangeEvent).target;
        formik.setFieldValue(name, value);
        formik.setFieldValue('sub_industries', null);
    };

    const handleMultipleSelect = (event: unknown | SelectChangeEvent) => {
        const { name, value } = (event as SelectChangeEvent).target;
        const newValue = Array.isArray(value)
            ? value.length
                ? multipleSelectOptionsToString(value)
                : null
            : value;

        formik.setFieldValue(name, newValue);
    };

    const handleSubIndustryDeleteClick = (value: string) => {
        const newArray = stringToMultipleSelectOptions(
            formik.values.sub_industries
        )?.filter((subIndustry) => {
            return subIndustry !== value;
        });
        const newValues = multipleSelectOptionsToString(newArray);

        formik.setFieldValue(
            'sub_industries',
            newValues === '' ? null : newValues
        );
    };

    const handleCountryDeleteClick = (value: string) => {
        const newArray = stringToMultipleSelectOptions(
            formik.values.country_listed
        )?.filter((country) => {
            return country !== value;
        });
        const newValues = multipleSelectOptionsToString(newArray);

        formik.setFieldValue(
            'country_listed',
            newValues === '' ? null : newValues
        );
    };

    const industryOptions = industriesData.map((industry) => ({
        value: industry.code,
        label: industry.name,
    }));

    const subIndustriesOptions = industriesData
        .find((industry) => industry.code === formik.values.industry)
        ?.sub_industries.map((subIndustry) => ({
            value: subIndustry.code,
            label: subIndustry.name,
        }));

    const companyOptions = companiesData.map((company) => ({
        value: company.isin,
        label: company.name,
    }));

    const countriesOptions = countries.map((country) => ({
        value: country.code,
        label: country.name,
    }));

    const employeeNumberTypeOptions = EnumToDropdownOptions(
        CompanyInformationEmployeeNumber
    );

    const handleSave = () => {
        setIsSave(true);
        formik.submitForm();
    };

    const handleSaveAndContinue = () => {
        setIsSave(false);
        formik.submitForm();
    };

    return (
        <form>
            <Stack spacing={4}>
                <Stack direction={'row'} spacing={2}>
                    <CustomSelectField
                        options={companyOptions}
                        onChange={handleSelect}
                        {...generateSelectFieldProps<FormikTypes>(
                            'isin',
                            'Company',
                            formName,
                            formik
                        )}
                        disabled
                    />
                    <DesktopDatePicker
                        label='AR release date'
                        inputFormat='DD/MM/YYYY'
                        value={formik.values.ar_publication_date ?? null}
                        onChange={(newValue) => {
                            formik.setFieldValue(
                                'ar_publication_date',
                                newValue
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                variant='outlined'
                            />
                        )}
                        disabled={isReadOnly}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <DesktopDatePicker
                        label='Fiscal Year End'
                        inputFormat='DD/MM/YYYY'
                        value={formik.values.fiscal_year_end ?? null}
                        onChange={(newValue) => {
                            formik.setFieldValue('fiscal_year_end', newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                variant='outlined'
                            />
                        )}
                        disabled={isReadOnly}
                    />
                    {/* <CustomTextField
                        {...generateTextFieldProps<FormikTypes>(
                            'employee_number',
                            'Employee Number',
                            formName,
                            formik
                        )}
                        disabled={isReadOnly}
                    /> */}
                    <CustomSelectField
                        options={employeeNumberTypeOptions}
                        onChange={handleSelect}
                        {...generateSelectFieldProps<FormikTypes>(
                            'employee_number',
                            'Number of Employees',
                            formName,
                            formik
                        )}
                        disabled={isReadOnly}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <CustomTextField
                        {...generateTextFieldProps<FormikTypes>(
                            'website',
                            'Website',
                            formName,
                            formik
                        )}
                        disabled={isReadOnly}
                    />
                    <CustomSelectField
                        options={industryOptions}
                        onChange={handleSelectIndustry}
                        {...generateSelectFieldProps<FormikTypes>(
                            'industry',
                            'Industry',
                            formName,
                            formik
                        )}
                        disabled={isReadOnly}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <CustomMultipleSelectField
                        options={countriesOptions}
                        onChange={handleMultipleSelect}
                        {...generateMultipleSelectFieldProps<FormikTypes>(
                            'country_listed',
                            'Country Registered',
                            formName,
                            formik
                        )}
                        handleDelete={handleCountryDeleteClick}
                        disabled={isReadOnly}
                    />
                    <CustomMultipleSelectField
                        options={subIndustriesOptions}
                        onChange={handleMultipleSelect}
                        {...generateMultipleSelectFieldProps<FormikTypes>(
                            'sub_industries',
                            'Sub - Industry',
                            formName,
                            formik
                        )}
                        handleDelete={handleSubIndustryDeleteClick}
                        disabled={isReadOnly}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <CustomTextField
                        {...generateTextFieldProps<FormikTypes>(
                            'sub_industry_rationale',
                            'Sub - Industry Rationale',
                            formName,
                            formik
                        )}
                        disabled={isReadOnly}
                    />
                    <div style={{ width: '100%' }} />
                </Stack>
                {!isReadOnly && (
                    <Stack
                        direction={'row'}
                        spacing={2}
                        justifyContent={'flex-end'}
                    >
                        <Button variant='outlined' onClick={onCancel}>
                            Close
                        </Button>
                        <Button variant='outlined' onClick={onGoBack}>
                            Go Back
                        </Button>
                        <Button variant='contained' onClick={handleSave}>
                            Save
                        </Button>
                        <Button
                            variant='contained'
                            onClick={handleSaveAndContinue}
                        >
                            Save and Continue
                        </Button>
                    </Stack>
                )}
            </Stack>
        </form>
    );
};
